<template>
    <el-dialog
        class="management-dialog"
        :close-on-click-modal="false"
        :visible.sync="showDialog"
        width="750px"
        append-to-body
        center
    >
        <div class="dialog-top" slot="title">
            <div class="text1">
                {{ $t('tu-pian-lun-bo') }}
            </div>
            <div class="text2">
                {{
                    $t(
                        'tu-pian-lun-bo-shi-yong-lai-qiang-hua-pin-pai-xing-xiang-zhan-shi-pin-pai-xuan-chuan-yu-ji-zhong-ti-xian-qi-ye-he-xin-xin-xi-ji-shi-li'
                    )
                }}
            </div>
        </div>

        <div class="tips">
            {{ $t('tu-pian-shang-chuan-yao-qiu') }}<br />
            {{ $t('1-zhi-chi-jpgpng-tu-pian-wen-jian-chi-cun-bu-di-yu-1920px600px-da-xiao-bu-chao-guo-5mb') }}
            <br />
            {{
                $t(
                    '2-pu-tong-zhan-shang-zui-duo-ke-shang-chuan-2-zhang-tu-pian-vip-zhan-shang-zui-duo-ke-shang-chuan-5-zhang-qi-zhong-di-yi-zhang-ke-yi-yong-shi-pin-dai-ti-zhi-chi-mp4-ge-shi-wen-jian-da-xiao-bu-chao-guo-50m'
                )
            }}
        </div>

        <div class="img-list" v-if="isVip">
            <div class="text1">{{ $t('shi-pin') }}</div>
            <div class="center">
                <video-upload name="video" :width="1920" :height="600" v-model="video" :ratio="[29, 9]" :imgWidth="290">
                </video-upload>
            </div>
        </div>

        <div class="img-list" v-for="(item, index) in list" :key="index">
            <div class="text1">{{ $t('tu-pian') }}{{ index + 1 }}</div>
            <div class="center">
                <crop-upload
                    :width="1920"
                    :height="600"
                    :name="`file${index}`"
                    v-model="item.value"
                    :ratio="[29, 9]"
                    :imgWidth="290"
                >
                </crop-upload>
            </div>
            <el-button
                type="text"
                plain
                size="mini"
                @click="move(index, -1)"
                :disabled="index === 0"
                icon="el-icon-top"
                >{{ $t('shang-yi') }}</el-button
            >
            <el-button
                type="text"
                plain
                size="mini"
                @click="move(index, 1)"
                :disabled="index === list.length - 1"
                icon="el-icon-bottom"
                >{{ $t('xia-yi') }}</el-button
            >
            <el-button type="text" plain size="mini" @click="del(index)" icon="el-icon-delete">{{
                $t('shan-chu')
            }}</el-button>
        </div>

        <el-button class="add" type="warning" v-if="canAdd" size="mini" @click="add">{{
            $t('tian-jia-geng-duo')
        }}</el-button>

        <div class="dialog-footer">
            <el-button type="warning" size="small" @click="submit">{{ $t('que-ding') }}</el-button>

            <el-button size="small" plain @click="showDialog = false">{{ $t('qu-xiao') }}</el-button>
        </div>

        <div class="bottom">
            <div class="bottom-text">{{ $t('tu-pian-she-ji-gui-fan') }}</div>
            <div class="bottom-content">
                <img src="../../../assets/banner_bianji_img.png" alt="" />

                <div class="bottom-tips">
                    <div>
                        {{ $t('shang-chuan-tu-pian-fen-bian-shuai-1920x600px') }}
                        <br />
                        {{ $t('jian-yi-zhu-yao-xin-xi-nei-rong-ju-zhong-zai-1200px-yi-nei-shen-huang-se-qu-yu') }}
                    </div>
                    <div>
                        {{ $t('ju-ti-ban-shi-ke-yi-zuo-ce-da-biao-ti-xiao-biao-ti-you-ce-pei-tu') }}
                        <br />
                        {{ $t('huo-zhe-da-biao-ti-xiao-biao-ti-ju-zhong-zhan-shi-pei-tu-zuo-wei-bei-jing') }}
                    </div>
                </div>
            </div>
        </div>
    </el-dialog>
</template>
<script>
import { mapState } from 'vuex';
export default {
    name: 'BannerDialog',
    props: {
        value: {
            type: String,
            default: ''
        },
        videoImg: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            showDialog: false,
            list: [],
            video: ''
        };
    },
    computed: {
        ...mapState(['userInfo']),
        canAdd() {
            let max = this.isVip ? 4 : 2;
            if (this.list.length < max) {
                return true;
            } else {
                return false;
            }
        },
        submitList() {
            var list = JSON.parse(JSON.stringify(this.list));
            if (this.video) {
                list.splice(0, 0, {
                    value: this.video.split(',')[0]
                });
            }

            return list
                .filter(item => {
                    return !!item.value;
                })
                .map(item => {
                    return item.value;
                })
                .join(',');
        }
    },
    methods: {
        show() {
            this.$nextTick(() => {
                console.log(this.videoImg);

                const banners = this.value?.split(',');
                this.list = banners
                    .filter(item => {
                        return this.checkImg(item);
                    })
                    .map(item => {
                        return {
                            value: item
                        };
                    });
                this.video =
                    banners.find(item => {
                        return !this.checkImg(item);
                    }) || '';
                if (this.video && this.videoImg) {
                    this.video = this.video + ',' + this.videoImg;
                }
                this.showDialog = true;
            });
        },
        add() {
            this.list.push({
                value: ''
            });
        },
        checkImg(file) {
            const imglist = ['png', 'PNG', 'jpg', 'JPG', 'jpeg', 'JPEG', 'bmp', 'BMP', 'gif', 'GIF'];
            const _info = file.split('.');
            if (imglist.indexOf(_info[_info.length - 1]) !== -1) {
                return true;
            } else {
                return false;
            }
        },
        move(index, direction) {
            const list = [...this.list];
            const startInfo = list[index];
            const endInfo = list[direction + index];

            list.splice(index, 1, endInfo);
            list.splice(direction + index, 1, startInfo);

            this.$set(this, 'list', list);
        },
        del(index) {
            const list = [...this.list];
            list.splice(index, 1);
            this.list = list;
        },
        submit() {
            this.$nextTick(() => {
                this.$emit('input', this.submitList);
                var videos = this.video.split(',');
                if (videos.length > 1) {
                    this.$emit('update:videoImg', videos[1]);
                }
                this.showDialog = false;
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.dialog-top {
    text-align: left;
    padding: 0 30px;
    .text1 {
        font-size: 16px;
        color: #ffffff;
        line-height: 22px;
    }

    .text2 {
        font-size: 12px;
        color: #bcc1cc;
        line-height: 17px;
        margin-top: 5px;
    }
}

.tips {
    font-size: 12px;
    color: #ffa526;
    line-height: 22px;
}

.img-box {
    width: 290px;
    height: 90px;
    background: #f5f7fa;
    border: 1px solid #dcdfe6;
    display: flex;
    flex-direction: column;
}

.img-list {
    display: flex;
    align-items: center;
    margin-top: 30px;

    .el-button {
        border-width: 0px;
        &.el-button--text {
            background-color: transparent;
            color: #878d99;
            &:hover {
                color: #ffa526;
            }
        }
    }

    .center {
        margin-right: 60px;
    }

    .text1 {
        min-width: 40px;
        text-align: right;
        font-size: 12px;
        color: #565b66;
        margin-right: 20px;
    }
}

.add {
    margin-top: 30px;
    margin-left: 60px;
}

.dialog-footer {
    display: flex;
    align-items: center;
    padding: 40px 0;
    justify-content: center;
    .el-button {
        min-width: 100px;
        height: 40px;
    }
}

.bottom {
    .bottom-text {
        font-size: 12px;
        font-weight: bold;
        color: #292c33;
        line-height: 17px;
    }
    .bottom-content {
        margin-top: 10px;
        img {
            width: 100%;
            height: auto;
            display: block;
        }
        position: relative;

        .bottom-tips {
            width: 269px;
            position: absolute;
            left: 50%;
            top: 51px;
            transform: translateX(-50%);

            font-size: 12px;
            font-weight: bold;
            color: #ffffff;
            line-height: 24px;
            text-align: center;

            div + div {
                margin-top: 30px;
            }
        }
    }
}
</style>

<style lang="scss">
.management-dialog {
    .el-dialog__header {
        background: #0f264d;
        padding: 20px 0;
    }

    .el-dialog__headerbtn {
        top: 30px;
        .el-dialog__close {
            font-size: 20px;
            &:hover {
                color: #fff;
            }
        }
    }
}
</style>
