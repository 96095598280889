<template>
    <el-dialog
        class="management-dialog"
        :close-on-click-modal="false"
        :visible.sync="showDialog"
        width="750px"
        append-to-body
        center
    >
        <div class="dialog-top" slot="title">
            <div class="text1">
                {{ $t('re-men-chan-pin') }}
            </div>
            <div class="text2">
                {{ $t('xuan-ze-zhu-tui-chan-pin-zai-re-men-tui-jian-wei-zeng-jia-chan-pin-pu-guang') }}
            </div>
        </div>

        <div class="select-top brand">
            <el-select v-model="customCateId" :placeholder="$t('xuan-ze-lei-bie')" @change="getData" clearable>
                <el-option v-for="item in menus" :key="item.id" :label="getName(item)" :value="item.id"> </el-option>
            </el-select>

            <div class="flex1">{{ $t('yi-jing-xuan-ze') }}{{ checkList.length }}{{ $t('ge') }}</div>

            <el-input
                size="mini"
                :placeholder="$t('sou-suo')"
                class="search"
                v-model="searchKey"
                clearable
                @change="submitSearch"
                @clear="submitSearch"
            >
                <div slot="append" @click="submitSearch" class="iconfont">
                    &#xe603;
                </div>
            </el-input>
        </div>

        <div class="content scrollbar" v-loading="loading">
            <div class="list">
                <div
                    class="product-item"
                    :class="{ checked: checkList.includes(item.id) }"
                    v-for="item in list"
                    :key="item.id"
                    @click="chooseItem(item.id)"
                >
                    <el-image :src="getImg(item.img)" fit="cover" :lazy="true"></el-image>
                    <div class="text">{{ getName(item) }}</div>

                    <div class="check">
                        <i class="el-icon-check"></i>
                    </div>
                </div>
            </div>

            <empty-page bgColor="transparent" size="mini" v-if="list.length === 0 && !this.loading">
                <template slot="text">
                    {{$t('zan-shi-shi-mo-du-mei-youo')}}
                </template>
            </empty-page>
        </div>

        <div class="dialog-footer" slot="footer">
            <el-link
                type="warning"
                :underline="false"
                target="_blank"
                href="https://www.imttech.cn/index/vendor/product"
                >{{$t('shang-pin-guan-li')}}</el-link
            >

            <el-button type="warning" size="small" @click="submit">{{$t('que-ding')}}</el-button>

            <el-button size="small" plain @click="showDialog = false">{{$t('qu-xiao')}}</el-button>
        </div>
    </el-dialog>
</template>
<script>
import { mapState } from 'vuex';
export default {
    name: 'hotDialog',
    props: {
        value: {
            type: Array,
            default: () => {
                return [];
            }
        }
    },
    data() {
        return {
            showDialog: false,
            list: [],
            customCateId: '',
            search: '',
            loading: false,
            checkList: [],
            menus: [],
            searchKey: '',
            allProduct: []
        };
    },
    computed: {
        ...mapState(['buyersInfo']),
        chooseProduct() {
            var allList = [...this.allProduct];
            var checkList = [...this.checkList];

            return checkList.map(item => {
                return allList.find(_item => {
                    return item === _item.id;
                });
            });
        }
    },
    mounted() {
        this.getData();
        this.$http
            .get('/customCategory/my', {
                vendorId: this.buyersInfo.vendorId
            })
            .then(res => {
                this.menus = res;
            });
    },
    methods: {
        getData() {
            this.loading = true;
            this.$http
                .get('/product/my', {
                    vendorId: this.buyersInfo.vendorId,
                    customCateId: this.customCateId,
                    search: this.search,
                    status: 0
                })
                .then(res => {
                    this.loading = false;
                    this.list = res;
                    if (!this.customCateId && !this.search) {
                        this.allProduct = res;
                    }
                })
                .catch(e => {
                    this.loading = false;
                    this.list = [];
                });
        },
        show() {
            this.$nextTick(() => {
                this.showDialog = true;
                this.getData();
                this.checkList = [...this.value].map(item => {
                    return item.id;
                });
            });
        },
        submit() {
            this.$nextTick(() => {
                this.$emit('input', this.chooseProduct);
                this.showDialog = false;
            });
        },
        getImg(img) {
            if (img) {
                return img.split(',')[0];
            } else {
                return '';
            }
        },
        chooseItem(id) {
            var list = [...this.checkList];
            if (list.includes(id)) {
                list.splice(list.indexOf(id), 1);
            } else {
                list.push(id);
            }
            this.checkList = list;
        },
        submitSearch() {
            this.$nextTick(() => {
                this.search = this.searchKey;
                this.getData();
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.dialog-top {
    text-align: left;
    padding: 0 30px;
    .text1 {
        font-size: 16px;
        color: #ffffff;
        line-height: 22px;
    }

    .text2 {
        font-size: 12px;
        color: #bcc1cc;
        line-height: 17px;
        margin-top: 5px;
    }
}
.select-top {
    display: flex;
    align-items: center;
    padding: 14px 0;

    .el-select {
        width: 260px;
    }

    .flex1 {
        font-size: 12px;
        color: #292c33;
        line-height: 17px;
        padding: 0 20px;
    }
}
.search {
    height: 32px;
    display: flex;
    width: auto;
    margin-left: 30px;
}
.dialog-footer {
    .el-button {
        min-width: 100px;
        height: 40px;
    }

    .el-link {
        float: left;
    }
}

.list {
    display: flex;
    flex-wrap: wrap;
    background: #f5f7fa;
    padding: 10px;
    min-height: 100%;
    box-sizing: border-box;
}
.content {
    position: relative;
    height: 459px;
    overflow-y: auto;
}
.product-item {
    margin: 7px;
    width: 20%;
    background-color: #fff;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    height: 180px;
    &:hover {
        box-shadow: 0 0 1px 1px #ffa526;
        .check {
            border-color: #ffa526;
        }
    }

    .text {
        font-size: 12px;
        color: #292c33;
        line-height: 17px;
        padding: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .check {
        width: 14px;
        height: 14px;
        border-radius: 2px;
        border: 1px solid #d5d8de;
        align-self: center;
        i {
            font-size: 10px;
            color: #fff;
        }
    }

    &.checked {
        .check {
            border-color: #ffa526;
            background-color: #ffa526;
        }
    }
}
</style>

<style lang="scss">
.management-dialog {
    .el-dialog__header {
        background: #0f264d;
        padding: 20px 0;
    }

    .el-dialog__headerbtn {
        top: 30px;
        .el-dialog__close {
            font-size: 20px;
            &:hover {
                color: #fff;
            }
        }
    }
}
</style>
