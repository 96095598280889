<template>
    <!-- 产品分类 选择组件 -->

    <Cascader
        v-model="value1"
        :list="categories"
        :type="props"
        :placeholder="$t('qing-xuan-ze-huo-zhe-shou-dong-shu-ru-wen-zi-hou-an-hui-che-tian-jia')"
        @input="select"
        :limit="limit"
    ></Cascader>

    <!-- <el-cascader
        v-model="value1"
        :options="categories"
        :props="props"
        @change="select"
        placeholder="请选择产品类别"
        class="input3"
        :show-all-levels="false"
    ></el-cascader> -->
</template>
<script>
import { delChild } from '../../utils/Array';
import Cascader from '../Cascader.vue';
export default {
    name: 'ProductCategorySelect',
    props: {
        value: {
            type: [String, Number, Array],
            default: ''
        },
        backString: {
            type: Boolean,
            default: true
        },
        multiple: {
            type: Boolean,
            default: true
        },
        label: {
            type: String,
            default: ''
        },
        limit: {
            type: Number,
            default: -1
        }
    },
    data() {
        return {
            categories: [],
            value1: {
                ids: [],
                labels: []
            }
        };
    },
    computed: {
        props() {
            return {
                expandTrigger: 'hover',
                value: 'id',
                label: this.$t('chname'),
                emitPath: false,
                checkStrictly: true,
                multiple: this.multiple
            };
        },
        _value() {
            if (this.backString && this.multiple) {
                return this.value.split(',');
            } else {
                return this.value;
            }
        },
        nowIds() {
            return this.value1.ids || [];
        },
        nowLable() {
            return (this.value1.labels || []).join(',');
        }
    },
    watch: {
        value() {
            this.$nextTick(() => {
                if (
                    (this._value && this.value1.ids && this.value1.ids.length === 0) ||
                    (!this._value && this.value1.ids && this.value1.ids.length !== 0)
                ) {
                    this.getvalue();
                }
            });
        },
        label() {
            this.$nextTick(() => {
                if (
                    (this.label && this.value1.label && this.value1.label.length === 0) ||
                    (!this.label && this.value1.label && this.value1.label.length !== 0)
                ) {
                    this.getvalue();
                }
            });
        }
    },
    mounted() {
        this.$http
            .get('/productCategory/tree')
            .then(res => {
                console.log(res);
                this.categories = delChild(res).map(item => {
                    return {
                        ...item,
                        disabled: true
                    };
                });
                this.getvalue();
            })
            .catch(e => {
                console.log(e);
                this.$message.error(e.error);
            });
    },
    methods: {
        getvalue() {
            this.value1 = {
                ids: this._value,
                labels: this.label ? this.label.split(',') : []
            };
            console.log(this.value1);
        },
        select() {
            this.$nextTick(() => {
                this.$emit('input', this.backString && this.multiple ? this.nowIds.join(',') : this.nowIds);
                this.$emit('update:label', this.nowLable);
            });
        },
        getTreeData(data) {
            console.log(data);
            // 循环遍历json数据
            for (let i = 0; i < data.length; i++) {
                if (!data[i].parentId) {
                    data[i].disabled = true;
                } else {
                    data[i].disabled = false;
                }
                if (!data[i].children || data[i].children.length < 1) {
                    // children若为空数组，则将children设为undefined
                    data[i].children = undefined;
                } else {
                    // children若不为空数组，则继续 递归调用 本方法
                    this.getTreeData(data[i].children);
                }
            }
            return data;
        }
    },
    components: {
        Cascader
    }
};
</script>
