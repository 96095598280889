<template>
    <el-dialog
        class="management-dialog"
        :close-on-click-modal="false"
        :visible.sync="showDialog"
        width="750px"
        append-to-body
        center
    >
        <div class="dialog-top" slot="title">
            <div class="text1">
                {{$t('dian-pu-dao-hang-lan')}}
            </div>
            <div class="text2">
                {{$t('vip-zhan-shang-ke-yi-xiu-gai-di-se')}}
            </div>
        </div>

        <div class="info">
            <div class="text1">{{$t('xiu-gai-dao-hang-lan-di-se')}}</div>
            <div class="color">
                <el-input readonly v-model="color1" size="normal"></el-input>

                <el-color-picker v-model="color1"></el-color-picker>
            </div>
        </div>

        <div class="dialog-footer" slot="footer">
            <el-button type="warning" size="small" @click="submit">确定</el-button>

            <el-button size="small" plain @click="showDialog = false">取消</el-button>
        </div>
    </el-dialog>
</template>
<script>
import { mapState } from 'vuex';
export default {
    name: 'BannerDialog',
    props: {
        value: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            showDialog: false,
            color1: '#0F264D'
        };
    },
    computed: {
        ...mapState(['userInfo'])
    },
    methods: {
        show() {
            this.$nextTick(() => {
                this.showDialog = true;
                this.color1 = JSON.parse(JSON.stringify(this.value)) || '#0F264D';
            });
        },
        submit() {
            this.$nextTick(() => {
                this.$emit('input', this.color1);
                this.showDialog = false;
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.dialog-top {
    text-align: left;
    padding: 0 30px;
    .text1 {
        font-size: 16px;
        color: #ffffff;
        line-height: 22px;
    }

    .text2 {
        font-size: 12px;
        color: #bcc1cc;
        line-height: 17px;
        margin-top: 5px;
    }
}

.dialog-footer {
    .el-button {
        min-width: 100px;
        height: 40px;
    }
}
.info {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 100px;

    font-size: 12px;
    color: #565b66;
    line-height: 17px;
}
.color {
    display: flex;
    align-items: center;
    margin-top: 10px;

    .el-input {
        width: 248px;
        margin-right: 15px;
    }
}
</style>

<style lang="scss">
.management-dialog {
    .el-dialog__header {
        background: #0f264d;
        padding: 20px 0;
    }

    .el-dialog__headerbtn {
        top: 30px;
        .el-dialog__close {
            font-size: 20px;
            &:hover {
                color: #fff;
            }
        }
    }
}
</style>
