<template>
    <div :style="defaultStyle" class="store" >
        <div class="storeHeader" :style="{ left: 0 - left + 'px' }">
            <div class="storeMain">
                <div class="storeInfo">
                    <div class="storeLogo" v-loading="loading">
                        <auto-img
                            class="logoImg"
                            :src="value.logo1 + '?x-oss-process=image/resize,h_204,m_lfit'"
                            mul="42.5%"
                        ></auto-img>
                    </div>
                    <div class="store-center">
                        <div class="store-title">
                            <span>{{ getName(value, ['chCompanyName', 'enCompanyName']) }}</span>
                            <img src="../../assets/icon_qiyerenzheng.png" alt="" class="qi" />
                            <!-- <img class="vip" src="../../assets/icon_vip.png" alt="" /> -->
                        </div>

                        <div class="text1">
                            {{ getName(value, ['chCompanyName', 'enCompanyName'], true) }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div :class="{ editContent: fixed, fixed: topFixed }">
            <div class="menu" :style="{ left: 0 - left + 'px', backgroundColor: color }">
                <div class="menu-center">
                    <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" router>
                        <el-menu-item
                            >{{ $t('guan-yu') }}{{ getName(value, ['chCompanyName', 'enCompanyName']) }}</el-menu-item
                        >
                        <el-submenu index="2">
                            <template slot="title">{{ $t('chan-pin-mu-lu') }}</template>
                            <el-menu-item v-for="item in menus" :key="item.id">{{ getName(item) }}</el-menu-item>
                        </el-submenu>
                        <el-menu-item>{{ $t('xin-wen-zi-xun') }}</el-menu-item>
                    </el-menu>
                </div>
            </div>

            <el-button v-if="fixed" type="primary" size="small" icon="el-icon-edit" @click="show('menuDialog')"
                >编辑</el-button
            >
        </div>
    </div>
</template>
<script>
import UserStoreOperation from '../../mixins/UserStoreOperation';
export default {
    name: 'Store',
    mixins: [UserStoreOperation],
    data() {
        return {
            defaultStyle: { height: '206px', backgroundColor: '#FFFFFF' },
            activeIndex: '2',
            searchVal: '',
            left: 0,
            collectionType: 'VENDOR',
            menus: [],
            topFixed: false
        };
    },
    props: {
        value: {
            type: Object,
            default: () => {
                return {};
            }
        },
        loading: {
            type: Boolean,
            default: false
        },
        decoration: {
            tyoe: Object,
            default: () => {
                return {};
            }
        },
        fixed: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        storeId() {
            return this.value.id;
        },
        categories() {
            return this.value.categories || [];
        },
        color() {
            return this.decoration.navigationColor || '#0F264D';
        }
    },
    watch: {
        storeId() {
            if (this.storeId) {
                this.$nextTick(() => {
                    this.cId = this.storeId;
                });
                this.getSelect();
            }
        }
    },
    methods: {
        getSelect() {
            this.$http
                .get('/customCategory/my', {
                    vendorId: this.storeId
                })
                .then(res => {
                    this.menus = res;
                    this.loading = false;
                });
        },
        goSearch(type) {
            if (type) {
                this.$router.push({
                    name: 'search',
                    query: {
                        search: this.searchVal,
                        searchType: 'product'
                    }
                });
            } else {
                this.$router.push({
                    name: 'brandProductList',
                    query: {
                        search: this.searchVal,
                        valueId: this.storeId,
                        firstSearch: 1
                    }
                });
            }
        },
        scrollEvent() {
            this.left = document.documentElement.scrollLeft;

            if (document.documentElement.scrollTop >= 252 - 90) {
                this.topFixed = true;
            } else {
                this.topFixed = false;
            }
        },
        show(name) {
            this.$emit('show', name);
        }
    },
    mounted() {
        if (this.fixed) {
            window.addEventListener('scroll', this.scrollEvent);
        }
    },
    beforeDestroy() {
        if (this.fixed) {
            window.removeEventListener('scroll', this.scrollEvent);
        }
    }
};
</script>
<style lang="scss" scoped>
@import '../../styles/variables';
.storeTop {
    position: sticky;
    top: 0px;
    z-index: 20;
}
.header {
    background-color: transparent;
    position: fixed;
    z-index: 200;
    top: 0;
    left: 0;
    padding: 0;
    // width: 100%;
    right: 0;
    background-color: #f5f7fa;
    min-width: 1200px;
}
.header-main {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90px;
    // max-width: 1920px;
    // padding: 0 50px;
    // margin: 0 auto;
    // min-width: 1200px;

    @include center-content(50);
}

.center {
    flex-grow: 1;

    display: flex;
    align-items: center;
    padding: 0 20px;
    justify-content: center;

    .el-button {
        padding: 0px 20px;
        font-size: 12px;
        line-height: 40px;
        border-width: 0px;
    }

    .el-button + .el-button {
        margin-left: 0;
    }
}

.logo {
    width: 170px;
    height: 60px;
    display: block;
    cursor: pointer;
}

.title {
    font-size: 24px;
    color: #000000;
    line-height: 50px;
    text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
    position: relative;
    padding-left: 100px;
    font-weight: lighter;

    &::before {
        content: '';
        width: 1px;
        height: 50px;
        background: #f2f3f5;
        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
        position: absolute;
        left: 40px;
        top: 0px;
    }
}

.connect {
    font-size: 20px;
    font-weight: bold;
    color: #818999;
    line-height: 28px;
    text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
    margin-right: 10%;
}

.storeHeader {
    background-color: #fff;

    &.fixed {
        position: fixed;
        z-index: 200;
        top: 90px;
        left: 0;
        padding: 0;
        right: 0;
        min-width: 1200px;
    }

    .storeMain {
        @include center-content(220);

        .storeInfo {
            display: flex;
            align-items: center;
            padding: 30px 0;
        }
    }
}

.storeLogo {
    width: 240px;
}

.store-center {
    flex-grow: 1;
    margin-left: 20px;
    align-self: center;
    .store-title {
        display: flex;
        align-items: center;
        font-size: 22px;
        font-weight: bold;
        color: #292c33;
        line-height: 30px;

        .qi {
            width: 21px;
            height: 24px;
            margin-left: 5px;
        }

        .vip {
            width: 46px;
            height: 24px;
            margin-left: 5px;
        }
    }

    .text1 {
        font-size: 14px;
        font-weight: bold;
        color: #818999;
        line-height: 20px;
        margin-top: 6px;
    }

    .text2 {
        font-size: 13px;
        color: #bcc1cc;
        line-height: 18px;
        margin-top: 8px;
    }
}

.store-cor {
    display: flex;
    align-items: center;
    .el-button {
        margin-left: 30px;
        .iconfont {
            color: #bcc1cc;
            font-size: 32px;
            // padding: 0 6px;
        }

        &.active {
            .btn-content {
                color: #ffa526;
            }
            .iconfont {
                color: #ffa526;
            }
        }
        .btn-content {
            color: #bcc1cc;
            display: flex;
            align-items: center;
            font-size: 12px;
        }

        &:hover {
            .iconfont {
                color: $--color-warning;
            }
            .btn-content {
                color: $--color-warning;
            }
        }

        &.zixun {
            .btn-content {
                .text {
                    color: #fff;
                    font-size: 14px;
                }
            }

            .iconfont {
                color: #fff;
                font-size: 22px;
                padding: 0 6px;
            }
        }
    }

    .el-button--warning {
        min-width: 126px;
        margin-left: 50px;
    }
}
.editContent {
    cursor: pointer;
    position: relative;

    &.fixed {
        position: fixed;
        top: 90px;
        left: 0;
        right: 0;
        z-index: 200;
    }

    &:hover {
        &::after {
            content: '';

            background: rgba(255, 165, 38, 0.2);
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            z-index: 30;
        }

        .el-button {
            display: block;
        }
    }

    .el-button {
        position: absolute;
        right: 30px;
        top: 7px;
        height: 30px;
        z-index: 31;
        min-width: 100px;

        border-width: 0px;
        background-color: rgba(#000, 0.3);
        display: none;

        &:hover {
            background-color: #ffa526;
        }
    }
}
.fixed {
    .menu {
        min-width: 1200px;
    }
}
.menu {
    height: 44px;
    background: #0f264d;

    padding: 0;

    .menu-center {
        @include center-content(480);
    }

    .el-menu {
        height: 44px;
        border-bottom: 0px;
    }
}
</style>
<style lang="scss">
@import '../../styles/variables';
.header {
    .center {
        .el-input__inner {
            border-color: $--color-primary;
            background-color: transparent;

            &:hover {
                border-color: $--color-warning;
            }

            &:focus {
                outline: none;
                border-color: $--color-warning;
            }
        }
    }
}
.store {
    .el-menu-item {
        line-height: 44px;
        height: 44px;
        border-width: 0px !important;

        &.is-active {
            color: #ffffff;
        }
    }
    .el-submenu .el-submenu__title {
        line-height: 44px;
        height: 44px;
        border-width: 0px !important;
    }
}

.el-menu.el-menu--popup {
    background-color: #fff;
    padding: 0 0;
    .el-menu-item {
        color: #292c33;
        padding: 0 20px;
        height: 50px;
        line-height: 50px;
        font-size: 14px;
        &:hover {
            background-color: rgba(255, 165, 38, 0.1);
            color: #ffa526;
        }
    }
    .el-submenu__title {
        color: #292c33;
        padding: 0 20px;
        height: 50px;
        line-height: 50px;
        font-size: 14px;
        &:hover {
            background-color: rgba(255, 165, 38, 0.1);
            color: #ffa526;
        }
    }

    .el-submenu.is-active {
        background-color: rgba(255, 165, 38, 0.1);
        .el-submenu__title {
            color: #ffa526;
        }
    }

    .el-menu-item.is-active {
        background-color: rgba(255, 165, 38, 0.1);
        color: #ffa526;
    }

    .el-menu.el-menu--popup {
        background-color: #f5f7fa;

        .el-submenu.is-active {
            background-color: #ffa526;
            .el-submenu__title {
                color: #fff;
            }
        }

        .el-menu-item.is-active {
            background-color: #ffa526;
            color: #fff;
            &:hover {
                color: #fff;
            }
        }

        .el-menu-item {
            &:hover {
                background-color: #ffa526;
                color: #fff;
            }
        }
        .el-submenu__title {
            &:hover {
                background-color: #ffa526;
                color: #fff;
            }
        }
    }
}
</style>
