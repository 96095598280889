<template>
    <div :style="defaultStyle">
        <el-header height="90px" id="header" class="header" :style="{ left: 0 - left + 'px' }">
            <div class="header-main">
                <img class="logo" @click="$router.push('/home')" src="../../assets/nav_logo.png" alt="" />

                <div class="title" v-if="pageTitle">{{ pageTitle }}</div>

                <div class="flex1"></div>

                <div class="right">
                    <slot name="right"></slot>
                </div>
            </div>
        </el-header>
    </div>
</template>
<script>
export default {
    name: 'Header',
    props: {
        pageTitle: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            defaultStyle: { height: '90px', backgroundColor: '#0F264D' },
            left: 0
        };
    },
    mounted() {
        window.addEventListener('scroll', this.scrollEvent);
    },
    methods: {
        scrollEvent() {
            this.left = document.documentElement.scrollLeft;
        }
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.scrollEvent);
    }
};
</script>
<style lang="scss" scoped>
@import '../../styles/variables';
.header {
    background-color: transparent;
    position: fixed;
    z-index: 200;
    top: 0;
    left: 0;
    // width: 100%;
    transition: all ease-in-out 0.3s;
    right: 0;
    background-color: #0f264d;
    padding: 0 0;
}
.header-main {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1920px;
    padding: 0 50px;
    height: 90px;
    margin: 0 auto;
    min-width: 1200px;
    box-sizing: border-box;
}
.el-menu {
    background-color: transparent;
    margin-left: 30px;
}

.el-menu.el-menu--horizontal.menu {
    border-bottom-width: 0;
}

.tip {
    flex-grow: 1;
    min-width: 10px;
}

.logo {
    width: 170px;
    height: 60px;
    display: block;
    cursor: pointer;
}

.title {
    font-size: 22px;
    color: #fff;
    line-height: 50px;
    text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
    position: relative;
    padding-left: 100px;
    font-weight: lighter;

    &::before {
        content: '';
        width: 1px;
        height: 50px;
        background: #f2f3f5;
        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
        position: absolute;
        left: 40px;
        top: 0px;
    }
}

.right {
    min-width: 480px;
}

.connect {
    font-size: 20px;
    font-weight: bold;
    color: #818999;
    line-height: 28px;
    text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
    margin-right: 10%;
}

.isEn {
    .title {
        font-size: 16px;
        padding: 0 20px;
        &::before {
            left: 10px;
        }
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
</style>

<style lang="scss">
.right {
    .el-button-group {
        border: 1px solid #ffa526;
        .el-button {
            min-width: 120px;

            height: 44px;
        }
        margin-right: 40px;
        .el-button {
            &.is-plain {
                &:hover {
                    background-color: rgba($color: #000000, $alpha: 0.3);
                }
            }
        }
    }

    .el-button {
        border-width: 0px;
        min-width: 80px;
        &.is-plain {
            background-color: transparent;
            &:hover {
                color: mix(#000000, #ffa526, 10);
            }
        }
    }
}
</style>
