<template>
    <div class="brandDetail">
        <StoreHeader v-model="info" :decoration="decoration" @show="show" />

        <div class="editContent">
            <swiper id="banner" ref="banner" class="swiper banner" :options="bannerOPtions" v-if="banners.length > 0">
                <swiper-slide v-for="item in banners" :key="item.id">
                    <auto-img :src="item" v-if="checkImg(item)" mul="31.25%"></auto-img>
                    <div class="video" v-else>
                        <video
                            :src="item"
                            controlsList="nodownload noremote footbar"
                            controls="controls"
                            style="height: 100%; max-width: 100%"
                            :poster="value.video"
                            oncontextmenu="return false;"
                            onmouseleave="leaveVideo(this)"
                        >
                            您的浏览器不支持 video 标签。
                        </video>
                    </div>
                </swiper-slide>
                <div
                    class="swiper-pagination swiper-pagination-right swiper-pagination-white"
                    slot="pagination"
                    v-siwperPagination
                ></div>
            </swiper>
            <auto-img v-else :src="require('../../assets/zhaungxiu_banner.png')" mul="31.25%"></auto-img>
            <el-button type="primary" size="small" icon="el-icon-edit" @click="show('bannerDialog')">编辑</el-button>
        </div>

        <div class="module-list">
            <div class="brand editContent" :style="{ order: decorationInfo.introductionOrder }">
                <div class="brandCenter">
                    <div class="content">
                        <div class="introduce" v-if="value.introduction">
                            {{ value.introduction }}
                        </div>
                        <empty-page size="mini" bgColor="transparent" noLogo v-else>
                            <template slot="text">
                                {{
                                    $t(
                                        'zhe-shi-qi-ye-jian-jie-wen-zi-zhan-shi-qu-yu-qing-jian-yao-miao-shu-qi-ye-de-zhu-yao-fu-wu-nei-rong-zhe-shi-rang-cai-gou-shang-di-yi-shi-jian-le-jie-qi-ye-de-ji-ben-xin-xi-de-nei-rong-jian-yi-hao-hao-tian-xie-500-zi-yi-nei'
                                    )
                                }}
                            </template>
                        </empty-page>
                    </div>
                </div>

                <el-button type="primary" size="small" icon="el-icon-edit" @click="show('introductionDialog')">{{
                    $t('bian-ji')
                }}</el-button>
            </div>
            <div class="module editContent module-1 category" :style="{ order: decorationInfo.productOrder }">
                <div class="brandCenter">
                    <title-driver class="hot-title">
                        <span slot="title">{{ $t('zhu-yao-chan-pin-fu-wu') }}</span>
                        <span slot="sub">product</span>
                    </title-driver>

                    <div class="content">
                        <swiper class="service-swiper" :options="categoryOption" v-if="categories.length > 0">
                            <swiper-slide class="service" v-for="(item, index) in categories" :key="index">
                                {{ getName(item) }}
                            </swiper-slide>

                            <div class="swiper-pagination" v-siwperPagination slot="pagination"></div>
                        </swiper>
                        <empty-page size="mini" bgColor="transparent" v-else>
                            <template slot="text"> {{ $t('zan-wei-she-zhi-zhu-yao-chan-pin-fu-wu') }} </template>
                        </empty-page>
                    </div>
                </div>

                <el-button type="primary" size="small" icon="el-icon-edit" @click="show('categoriesDialog')">{{
                    $t('bian-ji')
                }}</el-button>
            </div>

            <div class="module editContent" :style="{ order: decorationInfo.applicationOrder }">
                <div class="caseCenter">
                    <title-driver class="hot-title">
                        <span slot="title">{{ $t('ying-yong-ling-yu-1') }}</span>
                        <span slot="sub">Application field</span>
                    </title-driver>

                    <div class="content">
                        <swiper
                            class="application-swiper"
                            :options="applicationOption"
                            :style="{
                                width: 170 * applications.length - 20 + 'px'
                            }"
                            v-if="applications.length > 0"
                        >
                            <swiper-slide v-for="(item, index) in applications" :key="item.id">
                                <Application
                                    :index="index"
                                    :name="getName(item)"
                                    :id="item.id"
                                    :storeId="value.id"
                                ></Application>
                            </swiper-slide>

                            <div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
                            <div class="swiper-button-next swiper-button-white" slot="button-next"></div>
                        </swiper>
                        <empty-page size="mini" bgColor="transparent" v-else>
                            <template slot="text"> {{ $t('zan-wei-she-zhi-ying-yong-ling-yu') }} </template>
                        </empty-page>
                    </div>
                </div>

                <el-button type="primary" size="small" icon="el-icon-edit" @click="show('applicationDialog')">{{
                    $t('bian-ji')
                }}</el-button>
            </div>

            <div class="module editContent" :style="{ order: decorationInfo.caseOrder }">
                <div class="caseCenter">
                    <title-driver class="hot-title">
                        <span slot="title">成功案例</span>
                        <span slot="sub">case</span>
                    </title-driver>
                    <div class="content">
                        <swiper class="swiper2" ref="hotSwiper" :options="productOption" v-if="cases.length > 0">
                            <swiper-slide class="swiper-slide2" v-for="item in cases" :key="item.id">
                                <Case :name="item.title" :img="item.img" :id="item.id"></Case>
                            </swiper-slide>
                            <div class="swiper-pagination" v-siwperPagination slot="pagination"></div>
                        </swiper>
                        <empty-page size="mini" bgColor="transparent" v-else>
                            <template slot="text"> 暂未设置成功案例 </template>
                        </empty-page>
                    </div>
                </div>

                <el-button type="primary" size="small" icon="el-icon-edit" @click="show('caseDialog')">编辑</el-button>
            </div>

            <div class="module editContent module-1" :style="{ order: decorationInfo.popularOrder }">
                <div class="productCenter">
                    <title-driver class="hot-title">
                        <span slot="title">热门产品</span>
                        <span slot="sub">Hot products</span>
                    </title-driver>
                    <div class="content">
                        <swiper class="product-swiper" :options="informationOption" v-if="vendorHotProduct.length > 0">
                            <swiper-slide v-for="item in vendorHotProduct" :key="item.id">
                                <ProductGride :info="item" :isNew="false" isHome></ProductGride>
                            </swiper-slide>
                            <div class="swiper-pagination" v-siwperPagination slot="pagination"></div>
                        </swiper>
                        <empty-page size="mini" bgColor="transparent" v-else>
                            <template slot="text"> 暂未设置热门产品 </template>
                        </empty-page>
                    </div>
                </div>

                <el-button type="primary" size="small" icon="el-icon-edit" @click="show('hotProductDialog')"
                    >编辑</el-button
                >
            </div>

            <div class="editContent vipImgsBox" :style="{ order: decorationInfo.customAreaOrder }">
                <div class="vipImgs" v-if="customAreaImgs.length > 0">
                    <img :src="item" v-for="(item, index) in customAreaImgs" :key="index" alt="" />
                </div>

                <auto-img v-else :src="require('../../assets/zhaungxiu_banner.png')" mul="31.25%"></auto-img>

                <el-button type="primary" size="small" icon="el-icon-edit" @click="show('customAreaDialog')"
                    >编辑</el-button
                >
            </div>
        </div>

        <BannerDialog ref="bannerDialog" v-model="info.img" :videoImg.sync="info.video" @input="submit" />

        <IntroductionDialog ref="introductionDialog" v-model="info.introduction" @input="submit" />
        <CategoriesDialog
            ref="categoriesDialog"
            v-model="info.categories"
            :label.sync="info.customCategory"
            @input="submit"
        />
        <ApplicationDialog ref="applicationDialog" v-model="info.applications" @input="submit" />
        <CaseDialog ref="caseDialog" v-model="info.cases" @input="submit" />

        <HotProductDialog ref="hotProductDialog" v-model="decoration.vendorHotProduct" @input="submit" />

        <CustomAreaDialog ref="customAreaDialog" v-model="decoration.customArea" @input="submit" />

        <MenuDialog ref="menuDialog" v-model="decoration.navigationColor" @input="submit" />

        <BrandPreview ref="brandPreview" v-model="value" :decorationInfo="decorationInfo"></BrandPreview>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
import Application from '../../components/brand/Application';
import Case from '../../components/brand/Case';
import ProductGride from '../../components/product/Gride';
import BannerDialog from './dialog/Banner';
import IntroductionDialog from './dialog/Introduction';
import CategoriesDialog from './dialog/Categories';
import ApplicationDialog from './dialog/Application';
import CaseDialog from './dialog/Case';
import HotProductDialog from './dialog/HotProduct';
import CustomAreaDialog from './dialog/CustomArea';
import StoreHeader from './StoreHeader.vue';
import MenuDialog from './dialog/Menu';
import BrandPreview from './dialog/BrandDetail.vue';

export default {
    name: 'BrandDetail',
    data() {
        return {
            bannerOPtions: {
                autoplay: {
                    delay: 3000,
                    stopOnLastSlide: false,
                    disableOnInteraction: false
                },
                speed: 600,
                preventClicks: false,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                    bulletClass: 'banner-bullet',
                    bulletActiveClass: 'banner-bullet-active'
                }
            },
            productOption: {
                slidesPerView: 2,
                slidesPerGroup: 2,
                slidesPerColumn: 1,
                spaceBetween: 20,
                slidesPerColumnFill: 'row',
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                    bulletClass: 'content-bullet',
                    bulletActiveClass: 'content-bullet-active'
                },
                centerInsufficientSlides: true
            },
            productList: [],
            informationOption: {
                slidesPerView: 'auto',
                spaceBetween: 24,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                    // type: 'progressbar',
                    bulletClass: 'progress-bullet',
                    bulletActiveClass: 'progress-bullet-active'
                }
            },
            applicationOption: {
                slidesPerView: 'auto',
                spaceBetween: 20,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                }
            },
            categoryOption: {
                slidesPerView: 'auto',
                spaceBetween: 20,
                centerInsufficientSlides: true,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                    // type: 'progressbar',
                    bulletClass: 'progress-bullet',
                    bulletActiveClass: 'progress-bullet-active'
                }
            },
            info: {},
            decoration: {}
        };
    },
    props: {
        value: {
            type: Object,
            default: () => {
                return {};
            }
        },
        decorationInfo: {
            type: Object,
            default: () => {
                return {};
            }
        }
    },
    computed: {
        ...mapState(['userInfo']),
        banners() {
            if (this.value.img) {
                return this.value.img.split(',');
            }
            return [];
        },
        applications() {
            return this.value.applications || [];
        },
        cases() {
            return (
                this.value.cases?.filter(item => {
                    return !item.del;
                }) || []
            );
        },
        categories() {
            let customCategory = this.value.customCategory
                ? this.getName(this.value, ['customCategory', 'enCustomCategory']).split(',')
                : [];
            return [
                ...(this.value.categories || []),
                ...customCategory.map(item => {
                    return { chName: item, enName: item };
                })
            ];
        },
        imgs() {
            if (this.value.img) {
                return this.value.img.split(',');
            } else {
                return [];
            }
        },
        customAreaImgs() {
            if (this.decorationInfo.customArea) {
                return this.decorationInfo.customArea.split(',');
            } else {
                return [];
            }
        },
        vendorHotProduct() {
            return this.decorationInfo.vendorHotProduct || [];
        }
    },
    mounted() {
        this.info = { ...this.value };
    },
    watch: {
        value() {
            if (!this.info.id) {
                this.info = { ...this.value };
            }
        },
        decorationInfo() {
            if (!this.decoration.id) {
                this.decoration = this.decorationInfo;
            }
        }
    },
    components: {
        Swiper,
        SwiperSlide,
        Application,
        Case,
        ProductGride,
        BannerDialog,
        IntroductionDialog,
        CategoriesDialog,
        ApplicationDialog,
        CaseDialog,
        HotProductDialog,
        CustomAreaDialog,
        StoreHeader,
        MenuDialog,
        BrandPreview
    },
    methods: {
        show(name) {
            console.log(this.$refs[name]);
            this.$refs[name].show();
        },
        submit() {
            this.$nextTick(() => {
                this.$emit('input', this.info);
                this.$emit('update:decorationInfo', this.decoration);
            });
        },
        checkImg(file) {
            const imglist = ['png', 'PNG', 'jpg', 'JPG', 'jpeg', 'JPEG', 'bmp', 'BMP', 'gif', 'GIF'];
            const _info = file.split('.');
            if (imglist.indexOf(_info[_info.length - 1]) !== -1) {
                return true;
            } else {
                return false;
            }
        },
        preview() {
            this.$refs.brandPreview.showDialog = true;
        }
    }
};
</script>
<style lang="scss" scoped>
@import '../../styles/variables';
.brandDetail {
    .swiper-pagination-right {
        width: auto;
        left: auto;
        right: 30px;
    }
}

.swiper2 {
    padding-bottom: 60px;
}

.brand {
    background-color: #fff;
    padding: 70px 0 !important;
}
.productCenter {
    @include center-content(360, 1920, 0px);
}

.brandCenter {
    @include center-content(570);
}

.caseCenter {
    @include center-content(410);
}

.introduce {
    font-size: 14px;
    color: #292c33;
    line-height: 24px;
    text-align: center;
    padding: 100px 0 0;
}

.module {
    padding: 140px 0;
    background-color: #fff;
    &.module-1 {
        background-color: #f5f7fa;
    }

    .hot-title {
        padding-bottom: 60px;
    }
    &.category {
        padding: 140px 0 70px;
    }
}

$colors: [#6060b3, #607cb3, #6097b3, #60b397, #a2b360, #B39A60];

.serviceList {
    display: flex;
    align-items: center;
    justify-content: center;
    .service + .service {
        margin-left: 20px;
    }
    min-height: 60px;
}
.service-swiper {
    padding-bottom: 70px;
    .swiper-pagination {
        bottom: 40px;
        left: 50%;
        transform: translate(-50%);
    }
}
.service {
    font-size: 14px;
    color: #ffffff;
    line-height: 60px;
    // min-width: 124px;
    padding: 0 20px;
    height: 60px;
    text-align: center;
    cursor: pointer;
    box-sizing: border-box;
    white-space: nowrap;
    display: inline-block;
    width: auto;

    @each $color in $colors {
        $index: index($colors, $color);
        &:nth-child(6n + #{$index}) {
            background-color: $color;

            &:hover {
                background-color: mix($--color-black, $color, $--button-active-shade-percent);
            }
        }
    }
}

.applicationsContent {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.application-swiper {
    max-width: 100%;
    height: 180px;
    .swiper-slide {
        width: 150px;
    }

    .swiper-button-prev {
        position: absolute;
    }
}
.applications {
    display: flex;
    // justify-content: center;
    overflow-x: auto;
    flex-wrap: nowrap;
    max-width: 100%;

    // .application + .application {
    //     margin-left: 20px;
    // }
}

.product-swiper {
    padding: 0 50px 60px;
    .swiper-slide {
        width: 240px;
        padding: 30px 0;
    }

    .swiper-pagination {
        top: auto;
        bottom: 0px;
    }
}

// @media screen and (min-width: 1690px) {
//     .product-swiper {
//         padding-left: calc(50% - 795px);
//     }
// }

.vipImgsBox {
    background-color: #ffff;
}
.vipImgs {
    @include center-content(520, 1920, 0px);
    padding-top: 80px;
    padding-bottom: 80px;
    img {
        display: block;
        width: 100%;
        height: auto;
    }
}

.content {
    position: relative;
    min-height: 200px;
}

.editContent {
    position: relative;
    cursor: pointer;

    &:hover {
        &::after {
            content: '';

            background: rgba(255, 165, 38, 0.2);
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            z-index: 30;
        }

        .el-button {
            display: block;
        }
    }

    .el-button {
        position: absolute;
        right: 30px;
        top: 30px;
        height: 40px;
        z-index: 31;
        min-width: 100px;

        border-width: 0px;
        background-color: rgba(#000, 0.3);
        display: none;

        &:hover {
            background-color: #ffa526;
        }
    }
}
.video {
    background-color: #000;
    height: 31.25vw;
    min-height: 1200px * 0.3125;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    video {
        height: 31.25vw;
        min-height: 1200px * 0.3125;
        width: 100%;
    }
}

.module-list {
    display: flex;
    flex-direction: column;
}
</style>
<style lang="scss">
.application-swiper {
    .swiper-button-prev.swiper-button-disabled,
    .swiper-button-next.swiper-button-disabled {
        opacity: 0;
    }
    .swiper-button-prev,
    .swiper-button-next {
        width: 50px;
        height: 100%;
        top: calc(var(--swiper-navigation-size) / 2);
        opacity: 0;
        transition: opacity ease-in-out 0.3s;

        // background-color: rgba($color: #000000, $alpha: 0.3);
    }

    &:hover {
        .swiper-button-prev,
        .swiper-button-next {
            opacity: 1;
        }
        .swiper-button-prev.swiper-button-disabled,
        .swiper-button-next.swiper-button-disabled {
            opacity: 0;
        }
    }
}
</style>
