<template>
    <el-dialog
        class="management-dialog"
        :close-on-click-modal="false"
        :visible.sync="showDialog"
        width="750px"
        append-to-body
        center
    >
        <div class="dialog-top" slot="title">
            <div class="text1">
                {{$t('zi-ding-yi-qu-yu')}}
            </div>
            <div class="text2">
                {{$t('ci-qu-yu-wei-kai-fang-qu-yu-yong-hu-ke-yi-zai-ci-qu-yu-ren-yi-shang-chuan-tu-pian-mei-zhang-tu-pian-du-hui-an-zhao-shun-xu-wu-feng-xi-wang-xia-pai-lie')}}
            </div>
        </div>

        <div class="tips">
            {{$t('tu-pian-shang-chuan-gui-fan')}}<br />
            {{$t('tu-pian-kuan-du-bu-di-yu-880px-zhi-chi-duo-tu-shang-chuan-wen-jian-ge-shi-jpgpnggif-dan-zhang-tu-pian-bu-chao-guo-2m')}}
        </div>

        <div class="content">
            <multi-upload v-model="img" backString :size="2"></multi-upload>
        </div>

        <div class="dialog-footer" slot="footer">
            <el-button type="warning" size="small" @click="submit">{{$t('que-ding')}}</el-button>

            <el-button size="small" plain @click="showDialog = false">{{$t('qu-xiao')}}</el-button>
        </div>
    </el-dialog>
</template>
<script>
import { mapState } from 'vuex';
export default {
    name: 'customAreaDialog',
    props: {
        value: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            showDialog: false,
            img: ''
        };
    },
    computed: {
        ...mapState(['userInfo'])
    },
    methods: {
        show() {
            this.$nextTick(() => {
                this.showDialog = true;
                this.img = JSON.parse(JSON.stringify(this.value));
            });
        },
        submit() {
            this.$nextTick(() => {
                this.$emit('input', this.img);
                this.showDialog = false;
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.dialog-top {
    text-align: left;
    padding: 0 30px;
    .text1 {
        font-size: 16px;
        color: #ffffff;
        line-height: 22px;
    }

    .text2 {
        font-size: 12px;
        color: #bcc1cc;
        line-height: 17px;
        margin-top: 5px;
    }
}

.dialog-footer {
    .el-button {
        min-width: 100px;
        height: 40px;
    }
}
.tips {
    font-size: 12px;
    color: #ffa526;
    line-height: 22px;
}

.content {
    padding: 12px 0;
}
</style>

<style lang="scss">
.management-dialog {
    .el-dialog__header {
        background: #0f264d;
        padding: 20px 0;
    }

    .el-dialog__headerbtn {
        top: 30px;
        .el-dialog__close {
            font-size: 20px;
            &:hover {
                color: #fff;
            }
        }
    }
}
</style>
