<template>
    <div class="application ">
        <!-- applicationField: id -->
        <div class="bg " :style="{ backgroundImage: `url(${img})` }"></div>
        <div class="fontYahei text">{{ name }}</div>
    </div>
</template>

<script>
export default {
    name: 'ApplicationPreview',
    props: {
        index: {
            type: Number,
            default: 0
        },
        name: {
            type: String,
            default: ''
        },
        storeId: {
            type: Number,
            default: 0
        },
        id: {
            type: Number,
            default: 0
        },
        img: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            bgs: [
                'http://oss.imttech.cn/case/application/yingyong_img_012x.jpg',
                'http://oss.imttech.cn/case/application/yingyong_img_022x.jpg',
                'http://oss.imttech.cn/case/application/yingyong_img_032x.jpg',
                'http://oss.imttech.cn/case/application/yingyong_img_042x.jpg',
                'http://oss.imttech.cn/case/application/yingyong_img_052x.jpg',
                'http://oss.imttech.cn/case/application/yingyong_img_062x.jpg',
                'http://oss.imttech.cn/case/application/yingyong_img_072x.jpg',
                'http://oss.imttech.cn/case/application/yingyong_img_082x.jpg'
            ]
        };
    }
};
</script>
<style lang="scss" scoped>
.application {
    width: 150px;
    height: 180px;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    flex-shrink: 0;
    display: block;

    .bg {
        width: 100%;
        height: 100%;
        transition: transform ease-in-out 0.3s;
        transform: translate3d(0, 0, 0);
    }

    &:hover {
        .bg {
            transform: scale(1.1);
        }
    }

    &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: #0f264d66;
        z-index: 1;
    }
}
.text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 16px;
    color: #ffffff;
    line-height: 21px;
    z-index: 2;
}
</style>
