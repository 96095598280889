<template>
    <el-dialog
        class="management-dialog"
        :close-on-click-modal="false"
        :visible.sync="showDialog"
        width="750px"
        append-to-body
        center
    >
        <div class="dialog-top" slot="title">
            <div class="text1">
                {{$t('ying-yong-ling-yu')}}
            </div>
            <div class="text2">
                {{$t('xuan-ze-qi-ye-dang-qian-chan-pin-de-ying-yong-ling-yu-you-na-xie')}}
            </div>
        </div>

        <div>
            <el-select v-model="applicationIds" multiple :placeholder="$t('qing-xuan-ze')">
                <el-option v-for="item in application" :key="item.id" :label="item.chName" :value="item.id">
                </el-option>
            </el-select>
        </div>

        <div class="dialog-footer" slot="footer">
            <el-button type="warning" size="small" @click="submit">{{$t('que-ding')}}</el-button>

            <el-button size="small" plain @click="showDialog = false">{{$t('qu-xiao')}}</el-button>
        </div>
    </el-dialog>
</template>
<script>
import { mapState } from 'vuex';
import { flatChildren } from '../../../utils/Array';
export default {
    name: 'ApplicationDialog',
    props: {
        value: {
            type: Array,
            default: () => {
                return [];
            }
        }
    },
    data() {
        return {
            showDialog: false,
            applicationIds: [],
            application: []
        };
    },
    computed: {
        ...mapState(['userInfo']),
        allApplication() {
            return flatChildren([...this.application]);
        },
        chooseApplication() {
            var list = [...this.allApplication];
            return [...this.applicationIds].map(item => {
                let info = list.find(_child => {
                    return _child.id === item;
                });
                delete info.children;

                return info;
            });
        }
    },
    mounted() {
        this.$http
            .get('/productTag/application')
            .then(res => {
                this.application = this.getTreeData(res);
            })
            .catch(e => {
                console.log(e);
                this.$message.error(e.error);
            });
    },
    methods: {
        show() {
            this.$nextTick(() => {
                this.applicationIds = [...this.value].map(item => {
                    return item.id;
                });
                this.showDialog = true;
            });
        },
        submit() {
            this.$nextTick(() => {
                this.$emit('input', this.chooseApplication);
                this.showDialog = false;
            });
        },
        getTreeData(data) {
            // 循环遍历json数据
            for (let i = 0; i < data.length; i++) {
                if (data[i].children.length < 1) {
                    // children若为空数组，则将children设为undefined
                    data[i].children = undefined;
                } else {
                    // children若不为空数组，则继续 递归调用 本方法
                    this.getTreeData(data[i].children);
                }
            }
            return data;
        },
        changeSelect(value) {
            if (value.length > 6) {
                this.$message.warning(this.$t('zui-duo-xuan-ze-liu-ge-chan-pin-fu-wu'));
                this.applicationIds = value.filter((item, index) => {
                    return index < 6;
                });
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.dialog-top {
    text-align: left;
    padding: 0 30px;
    .text1 {
        font-size: 16px;
        color: #ffffff;
        line-height: 22px;
    }

    .text2 {
        font-size: 12px;
        color: #bcc1cc;
        line-height: 17px;
        margin-top: 5px;
    }
}

.dialog-footer {
    .el-button {
        min-width: 100px;
        height: 40px;
    }
}

.el-select {
    width: 100%;
}
</style>

<style lang="scss">
.management-dialog {
    .el-dialog__header {
        background: #0f264d;
        padding: 20px 0;
    }

    .el-dialog__headerbtn {
        top: 30px;
        .el-dialog__close {
            font-size: 20px;
            &:hover {
                color: #fff;
            }
        }
    }
}
</style>
