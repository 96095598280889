<template>
    <el-dialog
        class="management-dialog"
        :close-on-click-modal="false"
        :visible.sync="showDialog"
        width="750px"
        append-to-body
        center
    >
        <div class="dialog-top" slot="title">
            <div class="text1">
                {{ $t('cheng-gong-an-li') }}
            </div>
            <div class="text2">
                {{ $t('zeng-jia-qi-ye-yi-wang-de-cheng-gong-an-li-ke-yi-zeng-qiang-pin-pai-de-xin-yu-du') }}
            </div>
        </div>

        <div class="content">
            <empty-page size="mini" bgColor="transparent" v-if="showCase.length === 0">
                <template slot="text">
                    {{ $t('zan-wei-she-zhi-cheng-gong-an-li') }}
                </template>
            </empty-page>

            <div class="list">
                <div class="case-item" v-for="(item, index) in showCase" :key="item.id">
                    <el-image :src="item.img" fit="cover"></el-image>

                    <div class="flex1">
                        <div>
                            {{ item.title }}
                        </div>
                    </div>

                    <el-button
                        type="text"
                        icon="el-icon-top"
                        plain
                        size="small"
                        @click.stop.prevent="move(index, -1)"
                        class="pre"
                        :disabled="index === 0"
                        >上移</el-button
                    >
                    <el-button
                        type="text"
                        icon="el-icon-bottom"
                        plain
                        size="small"
                        @click.stop.prevent="move(index, 1)"
                        class="next"
                        :disabled="index === showCase.length - 1"
                        >{{ $t('xia-yi') }}</el-button
                    >

                    <el-button type="text" icon="el-icon-delete" plain size="small" @click.stop="del(index)">{{
                        $t('shan-chu')
                    }}</el-button>
                </div>
            </div>
        </div>

        <div class="dialog-footer" slot="footer">
            <el-link
                type="warning"
                :underline="false"
                target="_blank"
                href="https://www.imttech.cn/index/vendor/case?type=CASE"
                >{{ $t('guan-li-an-li') }}</el-link
            >
            <el-link type="primary" :underline="false" href="" target="_blank"></el-link>

            <el-button type="warning" size="small" @click="submit">{{ $t('que-ding') }}</el-button>

            <el-button size="small" plain @click="showDialog = false">{{ $t('qu-xiao') }}</el-button>
        </div>
    </el-dialog>
</template>
<script>
import { th } from 'date-fns/locale';
import { mapState } from 'vuex';
export default {
    name: 'CaseDialog',
    props: {
        value: {
            type: Array,
            default: () => {
                return [];
            }
        }
    },
    data() {
        return {
            showDialog: false,
            list: []
        };
    },
    computed: {
        ...mapState(['userInfo']),
        showCase() {
            return [...this.list].filter(item => {
                return !item.del;
            });
        }
    },
    methods: {
        show() {
            this.$nextTick(() => {
                this.list = JSON.parse(JSON.stringify(this.value));
                this.showDialog = true;
            });
        },
        submit() {
            this.$nextTick(() => {
                this.$emit('input', this.list);
                this.showDialog = false;
            });
        },
        move(index, direction) {},
        del(index) {
            this.$confirm(this.$t('shan-chu-jiang-wu-fa-hui-fu-que-ren-yao-shan-chu-mo'), this.$t('ti-shi'), {
                type: 'warning'
            })
                .then(() => {
                    const list = [...this.list];
                    list[index].del = true;
                    this.list = [...list];
                })
                .catch(e => {});
        }
    }
};
</script>

<style lang="scss" scoped>
.dialog-top {
    text-align: left;
    padding: 0 30px;
    .text1 {
        font-size: 16px;
        color: #ffffff;
        line-height: 22px;
    }

    .text2 {
        font-size: 12px;
        color: #bcc1cc;
        line-height: 17px;
        margin-top: 5px;
    }
}

.case-item {
    display: flex;
    align-items: center;
    border: 1px solid #e6e9f0;
    overflow: hidden;

    &:hover {
        border: 1px solid #ffa526;
    }

    .el-image {
        width: 147px;
        height: 84px;
        flex-shrink: 0;
    }

    .flex1 {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #292c33;
        line-height: 17px;
        padding: 15px;
        align-self: stretch;
        overflow: hidden;
        div {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
        }
    }
    .el-button {
        height: 36px;
        background-color: transparent;
        min-width: 60px;
        border-width: 0;
        color: #bcc1cc;

        &:hover {
            color: #292c33;
        }
    }
}

.dialog-footer {
    .el-button {
        min-width: 100px;
        height: 40px;
    }

    position: relative;
}

.content {
    position: relative;
    min-height: 200px;
}

.el-link {
    position: absolute;
    left: 15px;
    top: 12px;
}
</style>

<style lang="scss">
.management-dialog {
    .el-dialog__header {
        background: #0f264d;
        padding: 20px 0;
    }

    .el-dialog__headerbtn {
        top: 30px;
        .el-dialog__close {
            font-size: 20px;
            &:hover {
                color: #fff;
            }
        }
    }
}
</style>
