<template>
    <div class="Cascader">
        <el-cascader
            ref="elCascader"
            v-model="formValueIds"
            :options="showList"
            :props="defaultProps"
            clearable
            filterable
            :show-all-levels="false"
            @change="getformValue"
        >
            <template slot-scope="{ data }">
                <span>{{ getName(data) }}</span>
            </template>
        </el-cascader>
        <el-select
            ref="elSelect"
            v-model="formValue"
            multiple
            filterable
            allow-create
            default-first-option
            :placeholder="placeholder"
            @focus="inputFocus"
            @change="changeEvent"
            :automatic-dropdown="false"
            popper-class="miniSelect"
            :popper-append-to-body="false"
        >
            <el-option v-for="item in selectList" :key="item.id" :label="getLabel(item)" :value="item.id"> </el-option>
        </el-select>
    </div>
</template>

<script>
import { getFlatByKey, delChild } from '../utils/Array';
export default {
    name: 'Cascader',
    props: {
        value: {
            default: ''
        },
        list: {
            type: Array,
            default: []
        },
        placeholder: {
            type: String,
            default: ''
        },
        type: {},
        limit: {
            type: Number,
            default: -1
        }
    },
    data() {
        return {
            formValue: [],
            formValueIds: []
        };
    },
    computed: {
        selectList() {
            return getFlatByKey(this.list, this.$t('chname'));
        },
        showList() {
            return delChild(this.list);
        },
        nowValue() {
            if (this.value.ids) {
                return [...this.value.ids, ...this.value.labels];
            } else {
                return null;
            }
        },
        defaultProps() {
            if (this.type) {
                return this.type;
            } else {
                return {
                    multiple: true,
                    checkStrictly: true,
                    value: 'id',
                    label: this.$t('chname'),
                    emitPath: false,
                    expandTrigger: 'hover'
                };
            }
        }
    },
    watch: {
        nowValue() {
            if (this.nowValue && this.formValue.length !== this.nowValue.length) {
                this.formValueIds = [...this.value.ids];
                this.formValue = this.nowValue;
            }
        }
    },
    created() {
        this.changeEvent();
    },
    methods: {
        getLabel(info) {
            return this.getName(info);
        },
        cascaderChange(e) {
            console.log(e);
        },
        inputFocus(e) {
            this.$refs.elSelect.$refs.input.onclick = e => {
                this.$refs.elSelect.$el.getElementsByClassName('miniSelect')[0].style.opacity = 0;

                if (!this.$refs.elCascader.downVisible) {
                    this.$nextTick(() => {
                        this.getValueIds();
                        setTimeout(() => {
                            this.$refs.elSelect.visible = true;
                            this.$refs.elCascader.toggleDropDownVisible(true);
                        }, 10);
                    });
                }
            };
        },
        inputText(e) {
            console.log(e);
        },
        getValueIds() {
            let _values = [...this.formValue];
            let ids = _values.filter(item => {
                return typeof item === 'number';
            });
            this.formValueIds = [...ids];
        },
        getformValue() {
            let _values = [...this.formValue];
            let labels = _values.filter(item => {
                return typeof item === 'string';
            });
            let backValues = [...this.formValueIds, ...labels];

            if (this.limit !== -1 && backValues.length > this.limit) {
                this.$message.warning('最多选择' + this.limit + '个');
                let ids = _values.filter(item => {
                    return typeof item === 'number';
                });
                this.formValueIds = ids;
            } else {
                this.formValue = backValues;
                this.changeEvent();
            }
        },
        changeEvent(value) {
            let _values = [...(value || this.formValue)];
            if (this.limit !== -1 && _values.length > this.limit) {
                this.$message.warning(this.$t('zui-duo-xuan-ze-liu-ge-chan-pin-fu-wu'));
                _values = _values.filter((item, index) => {
                    return index < 6;
                });
            }

            let ids = _values.filter(item => {
                return typeof item === 'number';
            });
            let labels = _values.filter(item => {
                return typeof item === 'string';
            });

            this.$emit('input', {
                ids: ids,
                labels: labels
            });
        }
    }
};
</script>
<style lang="scss" scoped>
.Cascader {
    position: relative;
    width: 100%;
    .el-cascader {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        z-index: 1;
        opacity: 0;
    }
    .el-select {
        width: 100%;
        position: relative;
        z-index: 2;
    }
}
</style>
<style lang="scss">
.el-cascader__tags {
    background-color: #fff;
    left: 1px;
}
</style>
