<template>
    <div class="page">
        <Header
            :pageTitle="`${$t('dian-pu-zhuang-xiu')} — ${getName(vendorPageInfo, ['chCompanyName', 'enCompanyName'])}`"
        >
            <template slot="right">
                <el-button-group>
                    <el-button type="warning" size="small" :plain="type !== 'page'" @click="type = 'page'">{{
                        $t('ye-mian-bian-ji')
                    }}</el-button>
                    <el-button
                        class="vip"
                        type="warning"
                        size="small"
                        :plain="type !== 'container'"
                        @click="type = 'container'"
                        :disabled="!isVip"
                        >{{ $t('bu-ju-guan-li') }}</el-button
                    >
                </el-button-group>

                <el-button type="warning" size="small" plain @click="preview">{{ $t('yu-lan') }}</el-button>
                <el-button type="warning" size="small" plain @click="save">{{ $t('li-ji-fa-bu') }}</el-button>
            </template>
        </Header>

        <BrandDetail
            v-show="type === 'page'"
            v-model="vendorPageInfo"
            :decorationInfo.sync="decorationInfo"
            @input="edit = true"
            ref="detail"
        ></BrandDetail>
        <div>
            <div class="box" v-show="type === 'container'">
                <div class="box-title">
                    {{ $t('bu-ju-guan-li') }}
                    <div class="tag">{{ $t('zun-jing-de-vip-hui-yuan-ni-ke-yi-shi-yong-ci-gong-neng') }}</div>
                </div>

                <div class="box-list">
                    <div
                        class="box-item"
                        :style="{ order: decorationInfo[item.key] }"
                        :key="item.key"
                        v-for="item in boxList"
                    >
                        <div class="text1">{{ $t(item.title) }}</div>

                        <div class="flex1"></div>

                        <el-button
                            type="warning"
                            icon="el-icon-top"
                            :disabled="decorationInfo[item.key] === 1"
                            plain
                            size="mini"
                            @click.stop.prevent="move(item.key, -1)"
                            class="pre"
                            >{{ $t('shang-yi') }}</el-button
                        >
                        <el-button
                            type="warning"
                            icon="el-icon-bottom"
                            plain
                            size="mini"
                            @click.stop.prevent="move(item.key, 1)"
                            class="next"
                            :disabled="decorationInfo[item.key] === 6"
                            >{{ $t('xia-yi') }}</el-button
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import Header from '../../components/header/HeaderCustom.vue';
import BrandDetail from '../../components/management/BrandDetail.vue';
import { format } from 'date-fns';
export default {
    name: '',
    data() {
        return {
            vendorPageInfo: {},
            edit: false,
            decorationInfo: {},
            menus: [],
            type: 'page',
            boxList: [
                {
                    title: 'qi-ye-jian-jie',
                    key: 'introductionOrder'
                },
                {
                    title: 'zhu-yao-chan-pin-fu-wu',
                    key: 'productOrder'
                },
                {
                    title: 'ying-yong-ling-yu',
                    key: 'applicationOrder'
                },
                {
                    title: 'cheng-gong-an-li',
                    key: 'caseOrder'
                },

                {
                    title: 'zi-ding-yi-qu-yu',
                    key: 'customAreaOrder',
                    isVip: true
                },
                {
                    title: 're-men-chan-pin',
                    key: 'popularOrder'
                }
            ]
        };
    },
    computed: {
        ...mapState(['userInfo', 'buyersInfo']),
        orderMap() {
            const _map = new Map();

            [...this.boxList].forEach(item => {
                _map.set(this.decorationInfo[item.key], item.key);
            });

            return _map;
        }
    },
    components: {
        Header,
        BrandDetail
    },
    mounted() {
        console.log(this.buyersInfo);
        this.$http.get('/vendorInfo/getHome/' + this.buyersInfo.vendorId).then(res => {
            this.vendorPageInfo = res;
        });

        this.$http.post('/decoration/my?vendorId=' + this.buyersInfo.vendorId).then(res => {
            this.decorationInfo = res;
        });

        this.$http
            .get('/customCategory/my', {
                vendorId: this.buyersInfo.vendorId
            })
            .then(res => {
                this.menus = res;
            });
        window.addEventListener('beforeunload', this.beforeunloadEvent);
    },
    methods: {
        beforeunloadEvent(e) {
            if (this.edit) {
                var e = window.event || e;
                e.returnValue = this.$t('que-ding-li-kai-dang-qian-ye-mian-ma');
            }
        },
        save() {
            const loading = this.$loading({
                lock: true
            });
            const buyersInfo = { ...this.buyersInfo };
            buyersInfo.img = this.vendorPageInfo.img;
            buyersInfo.introduction = this.vendorPageInfo.introduction;
            buyersInfo.productCategoryId =
                this.vendorPageInfo.categories?.map(item => {
                    return item.id;
                }) || [];
            buyersInfo.applicationField =
                this.vendorPageInfo.applications?.map(item => {
                    return item.id;
                }) || [];

            buyersInfo.customCategory = this.vendorPageInfo.customCategory;
            buyersInfo.video = this.vendorPageInfo.video;

            const decoration = { ...this.decorationInfo };
            console.log(decoration);
            decoration.vendorHotProductId =
                decoration.vendorHotProduct?.map(item => {
                    return item.id;
                }) || [];
            delete decoration.vendorHotProduct;
            // if (buyersInfo.businessLicensePeriod) {
            //     buyersInfo.businessLicensePeriod = format(buyersInfo.businessLicensePeriod, 'yyyy-MM-dd HH:mm:ss');
            // }
            if (buyersInfo.noLimit) {
                buyersInfo.businessLicensePeriod = '';
            }

            Promise.all([
                this.$http.post('/buyersInfo/saveVO', buyersInfo, { body: 'json' }),
                this.$http.post('/decoration/save', decoration, { body: 'json' }),
                this.$http.post('/successCase/saveAll', {
                    all: JSON.stringify(this.vendorPageInfo.cases)
                })
            ])
                .then(res => {
                    this.edit = false;
                    loading.close();
                    this.$message.success(this.$t('fa-bu-cheng-gong'));
                    history.back();
                })
                .catch(e => {
                    this.$message.warning(e.error);
                });
        },
        move(key, direction) {
            const info = { ...this.decorationInfo };
            const now = info[key];
            const next = direction + now;
            info[this.orderMap.get(next)] = now;
            info[key] = next;
            this.decorationInfo = info;
        },
        preview() {
            this.$refs.detail.preview();
        }
    },
    beforeDestroy() {
        window.removeEventListener('beforeunload', this.scrollEvent);
    },
    beforeRouteLeave(to, from, next) {
        if (this.edit) {
            this.$confirm(this.$t('ye-mian-wei-fa-bu-que-ding-yao-li-kai-ci-ye-mian-ma'), this.$t('ti-shi'), {
                confirmButtonText: this.$t('li-ji-li-kai'),
                cancelButtonText: this.$t('qu-xiao'),
                type: 'warning'
            })
                .then(() => {
                    next();
                })
                .catch(() => {});
        } else {
            next();
        }
    }
};
</script>
<style lang="scss" scoped>
@import '../../styles/variables.scss';
.page {
    background-color: #f5f7fa;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}
.box {
    @include center-content(360);
    padding-top: 43px;
    padding-bottom: 43px;
}

.box-title {
    font-size: 22px;
    // font-weight: bold;
    color: #bcc1cc;
    line-height: 30px;
    display: flex;
    align-items: center;

    .tag {
        font-size: 14px;
        color: #ffa526;
        line-height: 20px;
        background: rgba(255, 165, 38, 0.12);
        padding: 4px 10px;
        margin-left: 12px;
    }
}

.box-list {
    padding: 4px 0;
    display: flex;
    flex-direction: column;
    .box-item {
        display: flex;
        align-items: center;
        padding: 0 30px;
        height: 70px;
        background: #ffffff;
        margin-top: 20px;
        transition: order ease-in-out 0.3s;
        .text1 {
            font-size: 16px;
            font-weight: bold;
            color: #292c33;
            line-height: 22px;
        }

        &:hover {
            box-shadow: 0 0 1px 1px #ffa526;
        }
    }
}
.el-button {
    position: relative;
    font-weight: normal;
    &.vip {
        &::after {
            content: 'vip';
            position: absolute;
            top: 0;
            right: 0;

            font-size: 12px;
            font-weight: normal;
            color: #ffffff;
            line-height: 14px;
            width: 28px;
            height: 15px;
            background: #ffc471;
        }
    }
}
</style>
