<template>
    <el-dialog
        class="management-dialog"
        :close-on-click-modal="false"
        :visible.sync="showDialog"
        width="750px"
        append-to-body
        center
    >
        <div class="dialog-top" slot="title">
            <div class="text1">
                {{ $t('qi-ye-jian-jie') }}
            </div>
            <div class="text2">
                {{
                    $t(
                        'qing-jian-yao-miao-shu-qi-ye-de-zhu-yao-fu-wu-nei-rong-zhe-shi-rang-cai-gou-shang-di-yi-shi-jian-le-jie-qi-ye-ji-ben-xin-xi-de-nei-rong-jian-yi-hao-hao-tian-xie-1000-zi-yi-nei'
                    )
                }}
            </div>
        </div>

        <div>
            <el-input
                type="textarea"
                v-model="message"
                :autosize="{ minRows: 11 }"
                :maxlength="1000"
                show-word-limit
                :placeholder="$t('qing-shu-ru')"
                resize="none"
            ></el-input>
        </div>

        <div class="dialog-footer" slot="footer">
            <el-button type="warning" size="small" @click="submit">{{ $t('que-ding') }}</el-button>

            <el-button size="small" plain @click="showDialog = false">{{ $t('qu-xiao') }}</el-button>
        </div>
    </el-dialog>
</template>
<script>
import { mapState } from 'vuex';
export default {
    name: 'IntroductionDialog',
    props: {
        value: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            showDialog: false,
            message: ''
        };
    },
    computed: {
        ...mapState(['userInfo'])
    },
    methods: {
        show() {
            this.$nextTick(() => {
                this.message = this.value;
                this.showDialog = true;
            });
        },
        submit() {
            this.$nextTick(() => {
                this.$emit('input', this.message);
                this.showDialog = false;
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.dialog-top {
    text-align: left;
    padding: 0 30px;
    .text1 {
        font-size: 16px;
        color: #ffffff;
        line-height: 22px;
    }

    .text2 {
        font-size: 12px;
        color: #bcc1cc;
        line-height: 17px;
        margin-top: 5px;
    }
}

.dialog-footer {
    .el-button {
        min-width: 100px;
        height: 40px;
    }
}
</style>

<style lang="scss">
.management-dialog {
    .el-dialog__header {
        background: #0f264d;
        padding: 20px 0;
    }

    .el-dialog__headerbtn {
        top: 30px;
        .el-dialog__close {
            font-size: 20px;
            &:hover {
                color: #fff;
            }
        }
    }
}
</style>
