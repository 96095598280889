<template>
    <div class="gride" :class="{ hasLogo: !noLogo, checked: checked && hasChoose }">
        <el-card :body-style="{ padding: '0px' }" :class="[shadow ? 'shadow' : 'border']">
            <img src="../../assets/icon_new.png" class="new-img" v-if="isNew" alt="" />
            <div class="top">
                <div
                    class="img bg"
                    :style="{
                        backgroundImage: `url(${img})`
                    }"
                ></div>
            </div>
            <div class="info">
                <div class="name" v-html="getImport(name)">
                    <!-- {{ name }} -->
                </div>
                <div class="sub">
                    <div class="sub-left animated fadeInBottomRight" v-if="!noLogo">
                        <Logo :logo="info.logo1 + '?x-oss-process=image/resize,h_204,m_lfit'" />
                    </div>
                    <span v-html="getImport(getName(info, ['model', 'enModel']))"></span>
                </div>

                <div class="tags" v-if="!isHome && !noLogo">
                    <el-tag size="mini" v-for="(item, index) in tag" :key="index">{{ item }}</el-tag>
                </div>

                <div class="bottom-content">
                    <template v-if="!noLogo">
                        <Logo
                            v-if="!vendorPage"
                            class="logo1"
                            :logo="info.logo1 + '?x-oss-process=image/resize,h_204,m_lfit'"
                        />
                        <template v-else>
                            <div class="btn-list">
                                <el-button
                                    v-if="info.deviceStatus === 'HAS_BEEN_REMOVED'"
                                    type="warning"
                                    plain
                                    size="small"
                                    @click.stop.prevent="offShelf(true)"
                                    >{{ $t('shang-jia') }}</el-button
                                >
                                <el-button
                                    v-else-if="info.deviceStatus === 'NOW_ON_SHELF'"
                                    type="info"
                                    plain
                                    size="small"
                                    @click.stop.prevent="offShelf(false)"
                                    >{{ $t('xia-jia') }}</el-button
                                >
                                <el-button v-else type="warning" plain size="small" @click.stop.prevent="" disabled>{{
                                    getStatus(info.deviceStatus)
                                }}</el-button>
                                <el-button type="warning" size="small" @click.stop.prevent="goEdit">编辑</el-button>
                            </div>
                        </template>
                        <template v-if="isHome">
                            <el-button
                                size="medium"
                                class="zixun bottom animated fadeInUp"
                                block
                                type="warning"
                                @click.stop.prevent="advisory(info, 'product')"
                            >
                                <div class="btn-content">
                                    <span class="iconfont">&#xe615;</span>{{ $t('kuai-su-xun-jia') }}
                                </div>
                            </el-button>
                        </template>
                        <el-row v-else class="bottom animated fadeInUp">
                            <el-button size="small" block @click.stop.prevent="addContrast(info.id)">{{
                                $t('tian-jia-dui-bi')
                            }}</el-button>
                            <el-button size="small" block type="warning" @click.stop.prevent="advisory(2)">{{
                                $t('kuai-su-xun-jia')
                            }}</el-button>
                        </el-row>
                    </template>
                </div>
            </div>
            <div class="check" @click.stop.prevent="chooseEvent" v-if="hasChoose">
                <el-checkbox v-model="checked" @change="changeCheck"></el-checkbox>
            </div>
        </el-card>
    </div>
</template>
<script>
import Logo from './Logo.vue';
import UserStoreOperation from '../../mixins/UserStoreOperation';
import { deviceStatus } from '../../utils/AppState';
export default {
    name: 'Gride',
    mixins: [UserStoreOperation],
    data() {
        return {
            checked: false,
            needCollect: false
        };
    },
    props: {
        shadow: {
            type: Boolean,
            default: false
        },
        isNew: {
            type: Boolean,
            default: true
        },
        info: {
            type: Object,
            default: () => {
                return {};
            }
        },
        isHome: {
            type: Boolean,
            default: false
        },
        noLogo: {
            type: Boolean,
            default: false
        },
        hasChoose: {
            type: Boolean,
            default: false
        },
        chooseList: {
            type: Array,
            default: () => {
                return [];
            }
        },
        // 会员编辑页
        vendorPage: {
            type: Boolean,
            default: false
        },
        search: {
            type: String,
            default: ''
        }
    },
    computed: {
        name() {
            return this.$i18n.locale.indexOf('zh') !== -1 ? this.info.chName : this.info.enName;
        },
        tag() {
            return (
                this.info.tag
                    .filter(item => {
                        return !!item;
                    })
                    .map(item => {
                        return this.getName(item);
                    })
                    .slice(0, 3) || []
            );
        },
        img() {
            if (this.info.img) {
                return this.info.img.split(',')[0] + '?x-oss-process=image/resize,m_fill,h_480,w_480';
            } else {
                return '';
            }
        },
        nowChooseList() {
            var _set = new Set([...this.chooseList]);
            if (this.checked) {
                _set.add(this.info.id);
            } else {
                _set.delete(this.info.id);
            }
            return [..._set];
        }
    },
    watch: {
        chooseList() {
            if (this.chooseList !== this.nowChooseList) {
                this.checked = this.chooseList.includes(this.info.id);
            }
        }
    },
    methods: {
        goDetail() {
            if (this.hasChoose) {
                this.checked = !this.checked;
                this.changeCheck();
            } else {
                this.$router.push('/productDetail?id=' + this.info.id);
            }
        },
        changeCheck() {
            this.$nextTick(() => {
                this.$emit('update:chooseList', this.nowChooseList);
            });
        },
        goEdit() {
            this.$router.push('/vendor/productEdit?id=' + this.info.id);
        },
        offShelf(pass) {
            this.$http
                .post('/product/take', {
                    id: this.info.id,
                    pass: pass
                })
                .then(res => {
                    this.$message.success(pass ? this.$t('shang-jia-cheng-gong') : this.$t('xia-jia-cheng-gong'));
                    this.$emit('refreash');
                })
                .catch(e => {
                    this.$message.warning(e.error);
                });
        },
        getStatus(key) {
            return this.$t(deviceStatus.get(key));
        },
        chooseEvent() {}
    },
    components: {
        Logo
    }
};
</script>
<style lang="scss" scoped>
@import '../../styles/variables';
.check {
    position: absolute;
    right: 10px;
    bottom: 10px;
}
.el-card {
    transition: all ease-in-out 0.3s;
    box-shadow: none;
    position: relative;
    height: 272px;
    border-width: 0px;

    &:hover {
        box-shadow: 0px 8px 17px 0px rgba(255, 165, 38, 0.12);
    }
}

.hasLogo {
    height: 100%;
    .el-card {
        transition: all ease-in-out 0.3s;
        box-shadow: none;
        position: relative;
        height: 100%;
        &.shadow {
            border: 1px solid #dcdfe6;
            &:hover {
                // box-shadow: 0px 8px 17px 0px rgba(255, 165, 38, 0.12);
                border: 1px solid rgba(255, 165, 38, 1);
                // transform: scale(1.1);

                // .logo1 {
                //     display: none;
                // }

                // .sub-left {
                //     display: block;
                // }
                // .bottom {
                //     display: block;
                //     &.el-row {
                //         display: flex;
                //     }
                // }
            }
        }

        &.border {
            transform: translate3d(0, 0, 0);
            &:hover {
                box-shadow: 0px 8px 17px 0px rgba(255, 165, 38, 0.12);
                border: 1px solid rgba(255, 165, 38, 1);
                transform: scale(1.1);

                .logo1 {
                    // display: none;
                    opacity: 0;
                }

                .sub-left {
                    display: block;
                }
                .bottom {
                    display: block;
                    &.el-row {
                        display: flex;
                    }
                }
            }
        }
    }

    .name {
        font-size: 14px;
        font-weight: normal;
        text-align: center;
    }
    .sub {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.checked {
    .el-card {
        &.shadow {
            border: 1px solid rgba(255, 165, 38, 1);
        }
    }
}
.logo1 {
    margin: 10px 18% 0;
    position: relative;
}

.tags {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    height: 20px;
    flex-wrap: wrap;
    overflow: hidden;
    .el-tag {
        background-color: transparent;
        color: #565b66;
        border: 1px solid #cfd5e1;
        border-radius: 0;

        &:not(:first-child) {
            margin-left: 6px;
        }
    }
}
.new-img {
    position: absolute;
    top: 0;
    left: 10px;
    width: 30px;
    height: 30px;
}
.top {
    // padding: 26px;
    .img {
        width: 100%;
        height: 0px;
        padding-top: 100%;
    }
}
.bottom-content {
    position: relative;
}
.bottom {
    width: 95%;
    display: none;
    margin: 0 auto 0;
    position: absolute;
    left: 5%;
    top: 10px;
    &.el-row {
        align-items: center;
        // display: flex;
        .el-button {
            flex-grow: 1;
            line-height: 16px;
        }

        .el-button--default {
            background-color: #f2f3f5;
            border-color: #f2f3f5;
            color: #565b66;
            min-width: 24%;
            &:hover {
                background-color: mix($--color-black, #f2f3f5, $--button-active-shade-percent);
                border-color: mix($--color-black, #f2f3f5, $--button-active-shade-percent);
            }
        }
    }
}

.name {
    font-size: 14px;
    font-weight: bold;
    color: $--color-text-regular;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 24px;
}

.sub {
    font-size: 12px;
    color: rgba(135, 141, 153, 1);
    line-height: 22px;
    margin-top: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    .sub-left {
        width: 24%;
        margin-right: 5px;
        display: none;
    }
}

.el-card {
    cursor: pointer;
}

.info {
    padding: 10px;
}

.bottom.zixun {
    line-height: 36px;
    .btn-content {
        font-size: 12px;
        height: 36px;
        .iconfont {
            font-size: 22px;
            padding: 0 6px;
        }
    }
}
.btn-list {
    display: flex;
    width: 100%;
    padding: 25px 0 10px;
    .el-button {
        flex-grow: 1;
    }
}
</style>
